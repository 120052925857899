<template>
    <div class="c-app flex-row align-items-center" :style="{backgroundImage: 'url('+organization_background+')', backgroundSize: 'cover','box-shadow': 'inset 0 0 0 1000px rgba(108, 122, 137, 0.35)' }" >
        <ul class="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
            </ul>
        <CContainer >
            <CRow class="justify-content-center">
                <CCol md="4">
                    <!-- <div class="invalid-organization" v-if="!organization_exists">
                        Invalid Organization. <br>Please ask your system Administrator.
                    </div>
                    <div class="invalid-email" v-if="!email_invalid">
                        Invalid Email. <br>Please ask your system Administrator.
                    </div> -->
                    <br>
                    <CCard class="p-4 login-panel" style="border-radius:20px;box-shadow: 0px 5px 80px 0px;">
                        <CForm @submit.prevent="forgotPassword">
                            <center>
                                <font-awesome-icon size="2xl" icon="user-lock"/>
                            <hr>
                            <h4>Having trouble logging in?</h4>
                            <p class="text-muted">Enter your email and we'll send you a link to get back into your account.</p>
                            </center>
                            <hr>
                            <CInput 
                                :lazy="false"
                                :value.sync="$v.contact.email.$model"
                                :isValid="checkIfValid('email')"
                                placeholder="Email" 
                                autocomplete="off" 
                                v-model="contact.email" 
                                type="email"
                            >
                                <template #prepend-content><CIcon name="cil-user" /></template>
                            </CInput>
                            <CRow>
                                 <CCol col="6" class="text-leftt" >
                                    <router-link :to="{path: `/${organization_name}`}">
                                        <CButton
                                            
                                            color="secondary" 
                                        > 
                                            <font-awesome-icon icon="arrow-left"/> Back to login
                                        </CButton>
                                    </router-link>
                                </CCol>
                                <CCol col="6" class="text-right" >
                                    <CButton 
                                        block
                                        :disabled="!isValid || !submit_btn" 
                                        type="submit" 
                                        color="primary" 
                                    >
                                        <font-awesome-icon 
                                            :icon="submit_btn ? 'sign-in-alt' : 'circle-notch'"
                                            :pulse="!submit_btn"
                                        /> Submit
                                    </CButton>
                                </CCol>
                            </CRow>
                        </CForm>
                    </CCard>
                </CCol>
            </CRow>
        </CContainer>
    </div>
</template>
<script>
import { required, email } from "vuelidate/lib/validators"
export default {
    name: "AccountRecovery",
    data() {
        return {
            organization_name: this.$route.params.orgName,
            organization_background: this.$backend_url+"/organization/images/background_image/"+this.$store.getters.getOrganizationKey,
            contact: {
                email: "",
            },
            submit_btn: true,
        };
    },
    computed: {
        isValid () { return !this.$v.contact.$invalid },
    },
    validations: { // FORM Validation (Vue)
        contact: {
            email: { required, email }
        },
    },
    created() {
        this.validate();
        document.title = "Account Recovery"
    },
    methods: {
        validate: function () {
            this.$v.contact.$touch()
        },
        checkIfValid: function (fieldName) {
            const field = this.$v.contact[fieldName]
            if (!field.$dirty) {
                return null
            } 
            return !(field.$invalid || field.$model === '')
        },
        validateForm: function () {
            if (!this.contact.email) {
                return false;
            }
            return true;
        },
        forgotPassword: function () {
            this.$Progress.start()
            window.axios.defaults.headers.common['Organization-Name'] = this.$store.getters.getOrganizationName
            window.axios.defaults.headers.common['Organization-Key'] = this.$store.getters.getOrganizationKey
            this.submit_btn = false
            if (this.validateForm()) {
                axios.post(process.env.VUE_APP_BACKEND_URL+"/forgot-password", this.contact, {validateStatus: () => true})
                .then((response) => {
                    if (response.status == 200) {
                        this.$swal({
                            toast: true,
                            position: "center",
                            showConfirmButton: false,
                            timer: 3000,
                            icon: "success",
                            title: "Please check your email.",
                            timerProgressBar: true,
                        }).then( () => {
                            this.submit_btn = true
                        });
                        return;
                    }
                    this.submit_btn = true
                })
            } else {
                this.$swal({
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 3000,
                    icon: 'error',
                    title: 'Please enter your email.',
                    timerProgressBar: true,
                })
            }
            this.$Progress.finish()
        },
        
    },
    watch: {
    }
}
</script>